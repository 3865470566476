<template>
    <div id="twinkle" v-bind:style="{ backgroundImage: 'url(' + background + ')' }">
        <div id="renderPanelWrapper">
            <ThreeView/>
        </div>
    </div>
</template>

<script>
  import ThreeView from './components/ThreeView.vue'

  export default {
    name: 'twinkle',
    components: {
      ThreeView
    },
    data: function () {
      return {
        now: new Date(),
        time: 0,
        background: ''
      }
    },
    created() {
      this.background = process.env.VUE_APP_BACKGROUND_STARS;
      const now = new Date();
      this.now = now.getTime();
      const time = new Date();
      this.time = time.getTime();
      window.setInterval(() => {
        const time = new Date();
        this.time = time.getTime();
      }, 88);
    }
  }
</script>

<style lang="scss">
    #twinkle {
        background-color: #102540;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;

        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;

        .object {
            img {
                position: absolute;
                height: 100%;
                width: 100%;
            }
        }

        #renderPanelWrapper {
            height: 100%;
            display: flex;
            position: relative;
            bottom: 0;
            left: 0;
            width: 100%;
        }

    }


</style>
