<template>
    <div class="panel" ref="renderPanel">

        <div class="starfield" v-bind:style="{ backgroundImage: 'url(' + starfield + ')' }"></div>
        <div class="starfield2" v-bind:style="{ backgroundImage: 'url(' + starfield + ')' }"></div>
        <div class="cloud1" v-bind:style="{ backgroundImage: 'url(' + cloud + ')' }"></div>
        <div class="moon-wrapper" id="moonWrapper" ref="moonWrapper">
            <img class="moon" v-bind:src="mond" alt="">
        </div>

        <div class="cloud2" v-bind:style="{ backgroundImage: 'url(' + cloud + ')' }"></div>

        <img ref="twinkle" class="twinkle" v-bind:src="twinkle" alt="">


    </div>
</template>

<script>
  const TWEEN = require('@tweenjs/tween.js');

  export default {
    name: 'ThreeView',
    data: function () {
      return {
        starfield: process.env.VUE_APP_STARFIELD,
        cloud: process.env.VUE_APP_CLOUDS,
        mond: process.env.VUE_APP_MOND,
        twinkle: process.env.VUE_APP_TWINKLE,
        tween1: false,
        twinkelElement: false,

      }
    },
    mounted() {
      this.twinkelElement = this.$refs.twinkelElement;
      this.init();
      this.animate();
    },
    methods: {
      init() {
        this.tween1 = new TWEEN.Tween({x: 0, y: 0})
          .to({x: [-10, -20], y: [-12, 0]}, 3000)
          .onUpdate((object) => {
            const transform = 'translateX(' + object.x + '%) translateY(' + object.y + '%)';
            this.$refs.moonWrapper.style.webkitTransform = transform;
            this.$refs.moonWrapper.style.transform = transform;

          })
          .interpolation(TWEEN.Interpolation.Bezier)
          .easing(TWEEN.Easing.Quadratic.InOut)
          .delay(2200).start();
        this.randomTwinkel();
        setInterval(this.randomTwinkel, 3000);

      },
      randomTwinkel() {
        const width = this.$parent.$el.clientWidth * Math.random();
        const height = this.$parent.$el.clientHeight * Math.random();
        const size = 60.0 * (1.0 - Math.random()/3);


        this.$refs.twinkle.style.top = height + 'px';
        this.$refs.twinkle.style.left = width + 'px';
        this.$refs.twinkle.style.width = size + 'px';

      },
      animate(time) {
        requestAnimationFrame(this.animate);
        TWEEN.update(time);
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

    @import "~breakpoint-sass/stylesheets/breakpoint";


    .panel {
        position: relative;
        width: 100%;
        top: 0;
        bottom: 0;
        overflow: hidden;

        .twinkle {
            position: absolute;
            width: 60px;
            opacity: 0;
        }

        .starfield {
            background-position: 60% 40%;
            background-size: 110%;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
            opacity: 0;
            animation: starfield_animation2 16s linear 0s infinite;
        }
        .starfield2 {
            background-position: 40% 40%;
            background-size: 110%;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
            opacity: 0;
            animation: starfield_animation 20s linear 10s infinite;
        }

        .cloud1 {
            height: 30%;
            width: 60%;
            top: 20%;
            position: absolute;
            background-size: contain;
            background-repeat: no-repeat;
            opacity: 0.8;
        }

        .cloud2 {
            height: 30%;
            width: 100%;
            bottom: 0;
            right: 0;
            position: absolute;
            background-size: contain;
            background-position: left bottom;
            opacity: 0.7;
            background-repeat: no-repeat;
            -moz-transform: scaleX(-1);
            -o-transform: scaleX(-1);
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);

        }
        $height-mini: (max-height 321px);
        $height-small: (max-height 400px);
        $height-med: (max-height 510px);

        $width-med: (max-width 999px);
        $width-small: (max-width 550px);
        $width-mini: (max-width 400px);
        $width-xlarge: (min-width: 1200px);
        $width-xxlarge: (min-width: 1440px);
        $width-xxxlarge: (min-width: 1620px);

        .moon {
            position: absolute;
            left: calc(50% - 265px);
            height: 534px;
            top: 20%;


            @include breakpoint($width-med) {
                height: 322px;
                left: calc(50% - 161px);
            }
            @include breakpoint($width-small) {
                height: 220px;
                left: calc(50% - 110px);
            }
            @include breakpoint($width-mini) {
                height: 186px;
                left: calc(50% - 93px);
            }

            @include breakpoint($width-xlarge) {
                left: calc(50% - 325px);
            }
            @include breakpoint($width-xxlarge) {
                top: 23%;
                height: 580px;
                left: calc(50% - 325px);
            }
            @include breakpoint($width-xxxlarge) {
                top: 23%;
                height: 620px;
                left: calc(50% - 415px);
            }

            @include breakpoint($height-med) {
                height: 300px;
                left: calc(50% - 150px);
                @include breakpoint($width-mini) {
                    height: 186px;
                    left: calc(50% - 93px);
                }
            }
            @include breakpoint($height-small) {
                height: 255px;
                left: calc(50% - 126px);
            }
            @include breakpoint($height-mini) {
                height: 215px;
                left: calc(50% - 108px);
            }

            animation: moon_rocking 5s ease-in-out 0s infinite alternate;

        }

        .twinkle {
            animation: star_spinning 3s linear 0s infinite;
        }

        @keyframes star_spinning {
            0% {
                opacity: 0;
                transform: rotateZ(0deg);
            }
            50% {
                opacity: 1;
            }
            100% {
                transform: rotateZ(180deg);
                opacity: 0;
            }
        }

        .moon-wrapper {
            position: absolute;
            height: 100%;
            width: 100%;
            //animation: moon_hüpf 3s linear 1s;
            //animation-fill-mode: forwards;
        }

        @keyframes moon_rocking {
            0% {
                transform: rotateZ(0deg);
            }
            50% {
                transform: rotateZ(-10deg);
            }
            100% {
                transform: rotateZ(0deg);
            }

        }

        @keyframes moon_hüpf {
            0% {
                transform: translateX(0%) translateY(0%);
            }
            30% {
                transform: translateX(-7%) translateY(-9%);
            }
            50% {
                transform: translateX(-10%) translateY(-12%);
            }
            70% {
                transform: translateX(-13%) translateY(-9%);
            }

            100% {
                transform: translateX(-22%) translateY(0%);
            }
        }

        @keyframes starfield_animation {
            0% {
                transform: scale(1) rotateZ(-5deg);
                opacity: 0.0;
            }
            50% {
                transform: scale(1.3) rotateZ(0deg);
                opacity: 1;
            }
            100% {
                transform: scale(1.6) rotateZ(5deg);
                opacity: 0.0;
            }
        }
        @keyframes starfield_animation2 {
            0% {
                transform: scale(1) rotateZ(170deg);
                opacity: 0.0;
            }
            50% {
                transform: scale(1.3) rotateZ(180deg);
                opacity: 1;
            }
            100% {
                transform: scale(1.6) rotateZ(190deg);
                opacity: 0.0;
            }
        }

    }
</style>

